import React from 'react';
import { useMemo } from 'react';
import IncidentsCard from '../card/incidentCard';
import { useTranslation } from 'react-i18next';

export default function IncidentListGrouped({ incidents }) {
  const { t } = useTranslation();

  const groupedIncidents = useMemo(() => {
    const groups = {};

    incidents.forEach((incident) => {
      if (incident.end_date !== null) {
        // Convert the creation_date to a JavaScript Date object
        const date = new Date(incident.creation_date);
        const year = date.getFullYear();
        const month = date.getMonth() + 1; // Months are zero-indexed

        // Initialize the year group if it doesn't exist
        if (!groups[year]) {
          groups[year] = { total: 0, months: {} };
        }

        // Initialize the month group if it doesn't exist
        if (!groups[year].months[month]) {
          groups[year].months[month] = { total: 0, incidents: [] };
        }

        // Increment the totals
        groups[year].total += 1;
        groups[year].months[month].total += 1;

        // Add the incident to the month group
        groups[year].months[month].incidents.push(incident);
      }
    });

    return groups;
  }, [incidents]);
  console.log(groupedIncidents);

  const getMonthName = (monthNumber) => {
    const date = new Date();
    date.setMonth(monthNumber - 1);
    const monthName = date.toLocaleString('default', { month: 'long' });
    return monthName ? monthName.charAt(0).toUpperCase() + monthName.slice(1) : '';
  };

  return (
    <div>
      {Object.keys(groupedIncidents)
        .sort((a, b) => b - a) // Sort years descending
        .map((year) => (
          <div key={year}>
            <h2>{t('YEARLY_INCIDENT', { year: year, total: groupedIncidents[year].total })}</h2>
            {Object.keys(groupedIncidents[year].months)
              .sort((a, b) => b - a) // Sort months descending
              .map((month) => (
                <div key={month}>
                  <h3>{t('MONTHLY_INCIDENT', { year: year, month: getMonthName(month), total: groupedIncidents[year].months[month].total })}</h3>
                  {groupedIncidents[year].months[month].incidents.map((incident) => {
                    return <IncidentsCard key={incident.incident_id} incident={incident} withClick />;
                  })}
                </div>
              ))}
          </div>
        ))}
    </div>
  );
}
