import React from 'react';
import Box from '@mui/material/Box';
import CircleIcon from '@mui/icons-material/Circle';
import Typography from '@mui/material/Typography';
import { DateUtiles } from 'src/services/dateUtiles';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { useHistory } from 'react-router-dom';
import { AuthenticationService } from 'src/services/auth';
import Grid from '@mui/material/Grid';
import { B2_BASE_URL } from 'src/constants/constants';
import { useQueryClient } from 'react-query';
import './incidentLogCard.scss';

export default function IncidentLogCard({ log, isLastElement }) {
  const history = useHistory();
  const module = history.location.pathname.split('/')[1];
  const queryClient = useQueryClient();
  const globalParam = queryClient.getQueryData(['params/global']);

  const { t } = useTranslation();

  const isIC = AuthenticationService.hasRole('INCIDENT_COMMANDER');

  return (
    <Box
      sx={{
        display: 'block',
        position: 'relative',
        margin: 1,
        '&:after': {
          content: !isLastElement && "''",
          position: 'absolute',
          top: '2rem',
          left: '14px',
          height: '100%',
          borderRight: '0.125rem solid rgb(222, 226, 230)',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#888',
          color: '#fff',
          width: '2rem',
          height: '2rem',
          left: '-1px',
          position: 'absolute',
          top: '0.7rem',
          borderRadius: '50%',
          zIndex: '2',
        }}
      >
        <CircleIcon
          sx={{
            fontSize: 'inherit !important',
          }}
        />
      </Box>
      <Box
        sx={{
          paddingTop: 1.5,
          marginLeft: 5.75,
          textAlign: 'justify',
          textJustify: 'inter-word',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography variant="subtitle2" component="div">
              {DateUtiles.toTime(log.event_date)}
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Typography variant="caption" component="div">
              {DateUtiles.toDate(log.event_date)}
            </Typography>
          </Grid>
        </Grid>

        <Typography variant="caption" component="div">
          {/* {`${t(log.entry_type)}`} */}
          {log.lastname !== '' && log.lastname !== null ? `${log.rank_abbreviation} ${log.lastname} ${log.firstname}` : null}
        </Typography>

        {log.subject ? `${log.subject} : ` : null}

        {(() => {
          switch (log.entry_type) {
            case 'PHOTO':
              let fileData = JSON.parse(log.comment);
              return (
                <>
                  <Box sx={{ whiteSpace: 'pre-line' }}>
                    <a href={`${globalParam.files_server || B2_BASE_URL}${fileData.fileName}`} target="_blank" tabIndex="0">
                      <img className={'sm-img'} src={`${globalParam.files_server || B2_BASE_URL}${fileData.smFileName}`} />
                    </a>
                  </Box>
                  <Box sx={{ whiteSpace: 'pre-line' }}>{fileData?.comment ?? ''}</Box>
                </>
              );
            case 'COMMENT':
              return <Box sx={{ whiteSpace: 'pre-line' }}>{log.comment}</Box>;
            case 'AUTO':
              return <Box sx={{ whiteSpace: 'pre-line' }}>{t(log.comment)}</Box>;
          }
        })()}
        {isIC ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row-reverse',
            }}
          >
            <IconButton size="small" onClick={() => history.push(`/${module}/intervention/${log.incident_id}/log/entry/${log.incident_log_id}`)}>
              <EditIcon />
            </IconButton>
          </Box>
        ) : null}

        <Box sx={{ paddingTop: 1, borderBottom: '0.1rem solid rgb(222, 226, 230)' }}></Box>
      </Box>
    </Box>
  );
}
